import { Button } from 'antd';
import { useEffect } from 'react';
import { ManagerStore } from '../../../DataSource/Stores/ManagerStore';
import Kanban from './Kanban';
import ModalAdd from './Kanban/ManagingOrder/Modal/Add';
import * as styles from './index.module.scss';

interface IProps {
  workspaceId: string;
  name: string;
}

const DeliveryCenter = ({ workspaceId }: IProps) => {
  useEffect(() => {
    document.title = 'Центр доставки';

    return () => {
      document.title = 'Центр доставки';
    };
  }, []);
  const showModal = () => {
    ManagerStore.ModalOpenOrder = true;
  };
  return (
    <div className={styles.distributionCenterPage}>
      <div className={styles.flex}>
        <p className="title_delivery">Центр доставки</p>
        <Button onClick={showModal} className={styles.but_neword}>
          Новый заказ
        </Button>
      </div>
      <Kanban workspaceId={workspaceId} />
      <ModalAdd />
    </div>
  );
};

export default DeliveryCenter;
